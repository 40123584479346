@import "../../../../global-style.scss";

.profile-overview-page-banner-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .page-label {
        padding-left: 5%;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        font: 500 1.2em Lato;

        .profile-overview-label {}

        .profile-overview-banner-tester-icon-container {
            display: flex;
        }
    }

    .profile-overview-page-banner {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .test-banner-button {
        height: 45px;
        width: 200px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        text-transform: none;
        font: 500 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .tester-container {

        .banner-info-container {

            .banner-data-info-container {

                .banner-data-label-container {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .banner-data-label {
                        font: 500 1em Lato;

                        .banner-tester-text-field {
                            width: 100%;
                        }
                    }

                    .countdown-data-container {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }
                }

                .banner-data-button-container {
                    margin-top: 10px;
                    display: flex;
                    gap: 10px;

                    .remove-button,
                    .save-button {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}