@import "../../../global-style.scss";

#upgrade-page {
    height: 100%;
    overflow: auto;

    .promo-code-container {
        display: flex;
        justify-content: center;

        .price-tag-icon-container {
            display: flex;
            align-items: center;
            gap: 10px;
            font: 600 1em Lato;

            .price-tag-icon {
                height: 15px;
            }
        }
    }

    .continue-button {
        height: 50px;
        width: 90%;
        max-width: 500px;
        margin: 15px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        cursor: pointer;
        font: 600 1.3em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .billed-amount {
        padding-bottom: 5px;
        text-align: center;
        font: 700 0.9em Lato;
        color: $sugarbook-main-color;
    }

    .tax-label {
        padding-bottom: 20px;
        text-align: center;
        font: bold 0.9em Lato;
        color: #7C7C7C;
    }

    .billed-info {
        padding: 0 20px 15px 20px;
        text-align: center;
        font: 500 0.8em Lato;
        color: #7C7C7C;

        .doublesize {
            font: 600 1.2em Lato;
        }
    }

    .cancellation-info {
        padding: 0 10px 15px 10px;
        text-align: center;
        font: 500 0.8em Lato;
        color: #7C7C7C;
    }

    .terms-and-condition-button {
        padding-bottom: 20px;
        cursor: pointer;
        text-align: center;
        text-decoration: underline;
        font: 600 1em Lato;
        color: $sugarbook-main-color;
    }
}