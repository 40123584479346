@import "../../../../global-style.scss";

#level-up-dialog {
    min-width: 200px;
    position: relative;

    .lottie-player-container {
        width: 100%;
        position: absolute;
        top: 0;
        pointer-events: none;
    }

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .level-up-title {
            font: 600 1.6em Lato;
        }

        .level-up-description {}

        .level-up-badge-container {
            padding: 10px 0;
            display: flex;

            .level-up-badge {
                width: 100px;
            }
        }

        .level-up-note {
            padding-bottom: 10px;
        }

        .got-it-button {
            height: 35px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}