@import "../../../global-style.scss";

#banner-tester-page {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .banner-tester-label {
        padding-left: 5%;
        font: 600 1.5em Lato;
    }

    .page-label {
        padding-left: 5%;
        padding-bottom: 10px;
        font: 500 1.2em Lato;
    }

    .test-banner-button {
        height: 45px;
        width: 200px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        text-transform: none;
        font: 500 1em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }

    .leaderboard-page-banner-container {

        .leaderboard-page-banner {
            padding: 0 10px;
        }
    }
}