@import "../../../../global-style.scss";

#leaderboard-user-shared-subcomponent {
    height: 65px;
    display: flex;
    align-items: center;
    gap: 10px;

    .trophy-container {
        width: 20px;
        display: flex;
        align-items: center;
    }

    .profile-photo {
        height: 40px;
        width: 40px;
        margin-right: 10px;
    }

    .user-description-container {
        height: 100%;
        width: calc(100% - 170px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;

        .top-container {
            display: flex;
            gap: 3px;

            .livestreaming-achievement-badge-container {
                display: flex;
                align-items: center;
                gap: 2px;

                .livestreaming-achievement-badge {
                    height: 13px;
                }
            }

            .username {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font: 600 0.9em Lato;
            }

            .badge-container {
                display: flex;
                align-items: center;
                gap: 3px;

                .level-container {
                    display: flex;
                    align-items: center;

                    .level-badge {
                        height: 13px;
                    }
                }

                .premium-container {}

                .verified-profile-container {

                    .verified-profile-badge {
                        height: 13px;
                    }
                }

                .verified-caller-container {

                    .verified-caller-badge {
                        height: 13px;
                    }
                }
            }
        }

        .bottom-container {
            display: flex;
            gap: 5px;

            .diamond-icon-container {
                display: flex;
                align-items: center;

                .diamond-icon {
                    width: 18px;
                }
            }

            .diamond-count {
                font: 600 0.9em Lato;
                color: rgb(77, 197, 255);
            }

            .coin-count {
                font: 600 0.9em Lato;
                color: rgb(253, 196, 83);
            }
        }
    }

    .follow-button {
        padding: 6px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $sugarbook-main-color;
        border-radius: 25px;
        cursor: pointer;
        font: 600 0.8em Lato;
        color: $sugarbook-main-color;
    }

    .following-button {
        padding: 6px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $sugarbook-main-color;
        border-radius: 25px;
        cursor: pointer;
        font: 600 0.8em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }
}