@import "../../../../global-style.scss";

#out-of-coins-dialog {
    max-width: 250px;

    .dialog-header {
        width: calc(100% - 30px);
        // position: absolute;
        padding: 15px 15px 0 15px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 24px;
                color: #999999;
            }
        }
    }

    .out-of-coins-container {

        .out-of-coins-padding-container {
            padding: 0 20px 40px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .coins-background-container {

                .out-of-coins-image {
                    width: 65px;
                }
            }

            .out-of-coins-text-container {
                padding-bottom: 20px;
                display: flex;
                flex-direction: column;
                gap: 5px;

                .out-of-coins-title {
                    text-align: center;
                    font: 900 1.3em Lato;
                }

                .out-of-coins-description {
                    text-align: center;
                    font: 500 0.8em Lato;
                }
            }

            .top-up-now-button {
                width: 90%;
                padding: 10px 0;
                border-radius: 50px;
                text-align: center;
                cursor: pointer;
                background: $sugarbook-main-color;
                color: white;
            }
        }
    }
}